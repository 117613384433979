import { AppConfig } from 'app/types/app.types'
import { TranslationNamespace } from 'app/types/translation.types'
import { format as formatDate, isValid as isDateValid } from 'date-fns'
import { Language } from 'frr-web/lib/theme/language'
import i18next from 'i18next'
import { default as Backend, default as I18NextLocizeBackend } from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import { initReactI18next } from 'react-i18next'
import { getFallbackUserLanguage } from './i18NextUserLanguage'
import { formatTimeRelativeFromNow } from 'shared/utils/format.helpers'

// Set (default) locale to user locale in moment
const locizeBackendOptions = (config: AppConfig): I18NextLocizeBackend['options'] => ({
  projectId: config.locize.productId,
  apiKey: config.locize.apiKey,
  referenceLng: Language.EN,
  version: config.locize.version,
})

const ns = [TranslationNamespace.COMMON]

export const i18n = i18next.createInstance()

export const initI18Next = (config: AppConfig) =>
  i18n
    // load translation using the locize service
    .use(Backend)
    .use(locizePlugin)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      backend: locizeBackendOptions(config),
      debug: false,
      fallbackLng: getFallbackUserLanguage(),
      fallbackNS: 'common',
      load: 'languageOnly',
      ns,
      // saveMissing: !!process.env.REACT_APP_LOCIZE_API_KEY,

      interpolation: {
        escapeValue: false, // react already safes from xss

        format: (value, format) => {
          let formattedValue = value

          if (format === 'Date') {
            formattedValue = isDateValid(value) ? formatDate(value, 'P') : ''
          } else if (format === 'Amount') {
            formattedValue = new Intl.NumberFormat('de-CH', {
              style: 'currency',
              currency: 'CHF',
              minimumIntegerDigits: 1,
            }).format(value.amount)
          } else if (format === 'ListIndex') {
            formattedValue = parseInt(value) + 1
          } else if (format === 'RelativeTime') {
            formattedValue = formatTimeRelativeFromNow(value)
          }

          return formattedValue
        },
      },

      react: {
        bindI18n: 'languageChanged',
      },
    })

export const addNamespacesToI18Next = (namespaces: Array<string>) => {
  namespaces.forEach((namespace) => {
    i18n.loadNamespaces(namespace)
  })
}
